import http from '../http.js';

const api = {
  /** 获取菜单 */
  getProjectMenu: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/index/getProjectMenu`,
      data: params,
    });
  },
};

export default api;
