import utils from "@/utils/utils";
import NavBar from "@/components/nav/index/index.vue";
import menuApi from "@/request/api/menu.js";

export default {
  name: "Home",
  mixins: [],
  components: {
    NavBar,
  },
  data() {
    return {
      meunlist: [],
    };
  },
  watch: {},
  filters: {},
  created() {},
  mounted() {
    // this.loadMenu();
  },
  methods: {
    handleNavChange(item) {
      // let { name } = item.router;
      // this.$router.push({ name });
    },
    // async loadMenu() {
    //   try {
    //     let res = await menuApi.getProjectMenu();
    //     this.meunlist = res;
    //   } catch (error) {}
    // },
  },
};
